var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[(_vm.perms.graph && this.isLoaded)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"text-center card-header"},[_c('span',{staticClass:"align-baseline h4"},[_vm._v("Historique du temps de réponse sur ")]),_c('b-button-group',{staticClass:"ml-2",attrs:{"size":"md"}},_vm._l((_vm.buttons),function(btn,idx){return _c('b-button',{key:idx,attrs:{"pressed":btn.state,"variant":"outline-dark"},on:{"click":function($event){return _vm.selectRange(btn, _vm.buttons)}}},[_vm._v(" "+_vm._s(btn.caption)+" ")])}),1)],1),_c('div',{staticClass:"card-body"},[(_vm.watchedUrls.length > 0 && _vm.values.length > 0 && _vm.isLoaded)?_c('b-row',{},[_c('line-chart',{attrs:{"library":{
                    "colors": _vm.chartColors.colors,
                    "plotOptions": {
                        "series": {
                            "marker" :{"enabled": false}
                        }
                    },
                    "xAxis": {
                        "labels" :{
                            "style": {"color": _vm.chartColors.font}
                        }
                    },
                    "yAxis": {
                        "labels" :{
                            "style": {"color": _vm.chartColors.font}
                        }
                    },
                    "tooltip": {
                        "valueDecimals": 2
                    },
                    "chart": {
                        "backgroundColor": _vm.chartColors.bg
                    },
                    "legend": {"itemStyle": {"color": _vm.chartColors.font}, "itemHoverStyle": {"color": _vm.chartColors.font_dark}}
                },"legend":"bottom","data":_vm.values,"width":"100%","height":"500px","min":0,"max":_vm.maxValue,"suffix":"s"}})],1):(!_vm.isLoaded)?_c('b-icon',{attrs:{"icon":"arrow-clockwise","animation":"spin","font-scale":"4"}}):_c('h2',{staticStyle:{"margin-top":"2%","text-align":"center"}},[_vm._v("Vous n'avez aucun site à monitorer.")])],1)]):(!_vm.isLoaded)?_c('div',{staticClass:"text-center mt-3"},[_c('b-icon',{attrs:{"icon":"arrow-clockwise","animation":"spin","font-scale":"4"}})],1):_vm._e(),(_vm.perms.backups && this.isLoaded)?_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('BackupTable')],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"text-center"},[_vm._v("Sauvegardes")])])
}]

export { render, staticRenderFns }